import React from "react";
import moment from "moment";
import "./App.css";

//let time = new Date().toLocaleString();

//const dateFormat = 'ddd MMMM' ;

const refreshMS = 100;

const timeUrl = "https://worldtimeapi.org/api/timezone/Etc/UTC";
const timeFormat = "YYYY-MM-DD[T]HH:mm:ss.SSSZZ";
//const formatTimeUnixMillis = "x";

class Clock extends React.Component {
    describeOffset(offset) {
        if (offset < 0) {
            return "ahead";
        } else {
            return "behind";
        }
    }

    detectLocalOffset() {
        // FIXME make this make multiple requests and average them
        // FIXME move setting of local state to this from makeRequestTo...
        // func
        const res = this.makeRequestToDetectLocalOffset();

        if (res) {
            this.setState({
                localOffsetLastCalculated: moment.utc().valueOf(),
                localOffsetMS: res.localOffset,
                fetchLagMS: res.fetchLagMS,
            });
        } else {
            this.setState({
                localOffsetMS: 0,
                localOffsetLastCalculated: moment.unix(0).valueOf(),
            });
        }
    }

    async makeRequestToDetectLocalOffset() {
        console.log("fetching remote time");
        var res = await this.fetchRemoteTime();
        if (res) {
            console.log("fetched remote time successfully");
            console.log("fetch rtt " + res.fetchLagMS + "ms");
            console.log("remote time is " + res.remoteTime.format(timeFormat));
            console.log(
                "remote time lag corrected is " +
                    res.remoteTimeLagCorrected.format(timeFormat)
            );
            console.log("local time is " + res.localTime.format(timeFormat));
            console.log(
                "local offset from remote is " + res.localOffset + "ms"
            );
            console.log(
                "local clock is " +
                    (res.localOffset < 0 ? "ahead of" : "behind") +
                    " server time"
            );
            return res;
        } else {
            console.log("unable to determine server time");
            return null;
        }
    }

    async fetchRemoteTime() {
        var output = {};
        const fetchStartTime = moment.utc().valueOf();
        var resp = await fetch(timeUrl);
        const fetchEndTime = moment.utc().valueOf();
        output.fetchLagMS = fetchEndTime - fetchStartTime;
        if (!resp.ok) {
            return null;
        }
        resp.json();

        output.localTime = moment.utc();
        output.remoteTime = moment.utc(resp.utc_datetime);
        output.remoteTimeLagCorrected = moment.utc(
            output.remoteTime.valueOf() + output.fetchLagMS / 2
        );
        // localOffset is positive if we are behind (remote is
        // larger), negative if we are ahead.
        output.localOffset =
            output.remoteTimeLagCorrected.valueOf() -
            output.localTime.valueOf();
    }

    componentDidMount() {
        this.intervalID = setInterval(() => this.tick(), refreshMS);
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    constructor(props) {
        super(props);
        this.state = {
            localOffsetMS: 0,
            localOffsetLastCalculated: moment.unix(0).valueOf(),
            systemTimeUTC: moment().utc(),
            systemTime: moment(),
        };
    }

    needsOffsetDetection() {
        if (this.state.localOffsetMS === null) {
            return true;
        }
        if (this.state.localOffsetMS === 0) {
            return true;
        }
        const now = moment.unix().valueOf();
        const msSinceLastOffsetDetection =
            now - this.state.localOffsetLastCalculated;
        // 60 mins between checks
        if (msSinceLastOffsetDetection > 1000 * 60 * 60) {
            return true;
        }
        return false;
    }

    tick() {
        this.setState({
            systemTimeUTC: moment().utc(),
            systemTime: moment(),
        });
        if (this.needsOffsetDetection()) {
            this.detectLocalOffset();
        }
    }

    offsetNeedsUpdate() {}

    render() {
        return (
            <div className="Clock">
                <code>
                    012345678911234567892123456789312345678941234567895123456789612345678971234567898
                    Local system time{" "}
                    {this.state.systemTimeUTC.format(timeFormat)}
                    {"\n"}
                    Local system time {this.state.systemTime.format(timeFormat)}
                    {this.state.localOffsetMS && (
                        <span>
                            Local system offset estimated{" "}
                            {Math.abs(this.state.localOffsetMS)}ms{" "}
                            {this.describeOffset(this.state.localOffsetMS)}
                        </span>
                    )}
                    {"\n"}
                    row 4 {"\n"}
                    row 5 {"\n"}
                    row 6 {"\n"}
                    row 7 {"\n"}
                    row 8 {"\n"}
                    row 9 {"\n"}
                    row 10 {"\n"}
                    row 11 {"\n"}
                    row 12 {"\n"}
                    row 13 {"\n"}
                    row 14 {"\n"}
                    row 15 {"\n"}
                    row 16 {"\n"}
                    row 17 {"\n"}
                    row 18 {"\n"}
                    row 19 {"\n"}
                    row 20 {"\n"}
                    row 21 {"\n"}
                    row 22 {"\n"}
                    row 23 {"\n"}
                    row 24 {"\n"}
                </code>
            </div>
        );
    }
}

export class App extends React.Component {
    render() {
        return (
            <div className="App">
                <Clock />
            </div>
        );
    }
}
